import { initializeCarousel } from './elements/carousels.js';

const $eventCalendarCarousel = document.querySelector('.EventCalendar');
const $eventCalendarCarouselPrev = document.querySelector('.EventCalendar__Button--previous');
const $eventCalendarCarouselNext = document.querySelector('.EventCalendar__Button--next');

const options = {
  actionPrev: $eventCalendarCarouselPrev,
  actionNext: $eventCalendarCarouselNext,
  itemsProperty: '--event-calendar-items',
  itemsPerPageProperty: '--event-calendar-items-per-page',
  indexProperty: '--event-calendar-index',
  breakpoints: [720, 1024],
  enableSwipe: 720,
  swipeOffsetProperty: '--event-calendar-swipe-offset',
  swipeTransitionLockClass: 'EventCalendar--swiping',
  wrapOnFirstAndLast: false
}

if ($eventCalendarCarousel) {
  initializeCarousel($eventCalendarCarousel, options);
}
